<template>
  <div>
    <!-- pic8D9FF69004BD21C7E2680C9EB9744D8A.jpg -->
    <img class="img-small rounded leftimage" src="@/assets/news-network/small/build-your-lu-dreams-p2.jpg">
    <p>
      Welcome back to this special series on the fun building features players will find in LEGO Universe! <br> <br>Readers of the <router-link :to="{ name: 'story', params: {id: 127606} }">first edition</router-link> already know the basics on the fastest ways to build in LEGO Universe: Quick Building and Choice Building. Today's edition delivers more detail on those features AND sheds light on two new ways to build in the first massively multiplayer online game (MMOG) for LEGO fans!
    </p>
    <p>
      As players explore and quest in LEGO Universe, they'll discover <b>Quick Build</b> models scattered throughout the gameworld. Putting together those models will help you and your friends achieve certain gameplay goals. For example, your minifigure can Quick Build 'bouncers' to help everyone leap up to spots where there are special LEGO bricks!
    </p>
    <p>
      <b>Choice Builds</b> spread around the gameworld will also help players advance, usually by solving puzzles. Imagine this scenario: You're LEGO Universe minifigure is stuck at the bottom of a massive cliff where you've just found a Choice Build that includes three models. Each LEGO model can boost you part way up the cliff, but to reach the top you must build all three in a particular order!
    </p>
    <p>
      Now that you can picture a few ways LEGO Universe players will have a blast building fast, let's take a peek at a couple of the game's more creative ways to build! We'll start the wheels rolling with <b>Modular Building.</b> This kind of building fun is based on premade 'modules' that are made up of several LEGO elements. Choose different module combinations to build custom LEGO models-- like radical rockets and race cars!
    </p>
    <p>
      And even the most advanced builders are going to have a ball <b>Free Building</b> in LEGO Universe! Show off your unique creative style by building custom LEGO models brick by brick!
    </p>
    <!-- pic6AEF097B99BC95879FA71885F26EB4DB.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/build-your-lu-dreams-p2.jpg">
    <p>
      Stay tuned to the LEGO Universe News Network for much more on how the game's launch will set off a LEGO building BOOM!
    </p>
  </div>
</template>
